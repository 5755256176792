<div class="modal" id="add-chatroom-modal" tabindex="-1" role="dialog" aria-labelledby="add-chatroom-modal-label" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="add-chatroom-modal-label">{{{o.__('Enter a new Groupchat')}}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="converse-form add-chatroom">
                    <div class="form-group">
                        <label for="chatroom">{{{o.label_room_address}}}:</label>
                        <input type="text" required="required" name="chatroom" class="form-control" placeholder="{{{o.chatroom_placeholder}}}"/>
                    </div>
                    {[ if (!o._converse.locked_muc_nickname) { ]}
                    <div class="form-group" >
                        <label for="nickname">{{{o.__('Nickname')}}}:</label>
                        <input type="text" pattern=".*\S+.*" title="{{{o.__('This field is required')}}}" required="required" name="nickname" value="{{{o.nick}}}" class="form-control"/>
                    </div>
                    {[ } ]}
                    <input type="submit" class="btn btn-primary" name="join" value="{{{o.__('Join')}}}"/>
                </form>
            </div>
        </div>
    </div>
</div>
